import React from 'react';

import * as styles from './IsotopeNavigation.module.css';
import { Link } from 'gatsby';

export default function IsotopeTeamNavigation({ departments, active, filterFn }) {
  return (
    <div className={styles.content}>
      <ul>
        <li className={active === null ? styles.active : ''} key="all">
          <Link
            to="/vertrieb/team"
            onClick={(e) => {
              e.preventDefault();
              filterFn('dep');
            }}
            title="Alle Mitarbeiter"
          >
            Alle
          </Link>
        </li>
        {departments
          .filter((node) => !node.Gruppierung)
          .sort((a, b) => {
            if (a.Bezeichnung.indexOf('SAME') >= 0) {
              return -1;
            } else if (b.Bezeichnung.indexOf('SAME') >= 0) {
              return 1;
            }
            return a.Bezeichnung.localeCompare(b.Bezeichnung.toUpperCase());
          })
          .map((node) => (
            <li key={node.slug} className={styles.active === node.id ? 'active' : ''}>
              <Link
                title={node.Bezeichnung}
                to={`/vertrieb/team#${node.slug}`}
                onClick={(e) => {
                  setTimeout(() => {
                    e.preventDefault();
                    filterFn(node.id, node.slug);
                  }, 1);
                }}
              >
                {node.Bezeichnung}
              </Link>
            </li>
          ))}
      </ul>
    </div>
  );
}
