import React from 'react';

import * as styles from './TeamMember.module.css';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import clsx from 'clsx';

function TeamMember({ entry }) {
  const { image } = entry;

  const classes = {
    'grid-item': true,
  };

  entry.departments.forEach((department) => {
    classes[`dep-${department.id}`] = true;
  });

  return (
    <div className={clsx(classes, styles.content)}>
      <div className={styles.image}>
        <GatsbyImage
          alt={entry.name}
          style={{ height: '100%' }}
          imgStyle={{ objectPosition: 'top center' }}
          image={getImage(image)}
        />
      </div>
      <div className={styles.infos}>
        <h4 style={{
          marginTop: 0
        }}>
          <span className="fn">{entry.Name}</span>
        </h4>
        {entry.company && (
          <div>
            <p>{entry.company}</p>
          </div>
        )}
        {entry.Aufgabenbereiche && (
          <div>
            {entry.Aufgabenbereiche.map((Aufgabenbereiche) => (
              <p key={Aufgabenbereiche}>{Aufgabenbereiche}</p>
            ))}
          </div>
        )}
        <div>
          {entry.Telefon && (
            <div>
              <span>Tel.: </span>
              <a href={`tel:${entry.Telefon}`} target="_blank">
                {entry.Telefon}
              </a>
            </div>
          )}

          {entry.Mobiltelefon && (
            <div>
              <span>Mobil: </span>
              <a href={`tel:${entry.Mobiltelefon}`} target="_blank">
                {entry.Mobiltelefon}
              </a>
            </div>
          )}

          {entry.Fax && (
            <div>
              <span>Fax: </span>
              <a href={`tel:${entry.Fax}`} target="_blank">
                {entry.Fax}
              </a>
            </div>
          )}

          {entry.Email && (
            <div className={styles.email}>
              <span>E-Mail: </span>
              <a href={`mailto:${entry.Email}`}>{entry.Email}</a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TeamMember;
