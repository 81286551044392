import React from 'react';

import IsotopeTeamNavigation from '../Team/IsotopeNavigation';

import * as styles from './Team.module.css';
import TeamMember from '../Team/TeamMember';

function groupTeamMembers(people) {
  const teams = {};
  people.map(({ node }) => {
    node.departments.forEach((department) => {
      teams[department.id] = teams[department.id] || [];
      teams[department.id].push(node);
    });
  });
  return teams;
}

function groupDepartments(departments) {
  const groupedDepartments = {};
  departments.map((node) => {
    if (node) {
      groupedDepartments[node.id] = groupedDepartments[node.id] || [];
      groupedDepartments[node.id].push({ node: node });
    } else {
      groupedDepartments[node.id] = [{ node: node }];
    }
  });
  return groupedDepartments;
}

export default class TeamPageContent extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.groupedPeople = groupTeamMembers(props.employees.edges);
    this.groupedDepartments = groupDepartments(props.departments.nodes);
    this.currentDepartments = props.pathContext
      ? this.groupedDepartments[props.pathContext.node.id]
      : props.departments.nodes;

    this.state = {
      active: this.props.pathContext ? this.props.pathContext.node.id : 'dep',
    };
  }

  updateDepartmentForHash = () => {
    try {
      const hash = window.location.hash.replace('#', '');
      console.log('found hash', hash);
      if (hash && hash.length > 0) {
        const departmentForHash = Object.values(this.groupedDepartments).filter((node) => {
          return node[0].node.slug.trim() === hash;
        });
        if (departmentForHash.length > 0) {
          const departmentId = departmentForHash[0][0].node.id;
          this.filter(departmentId);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  componentDidMount = () => {
    window.addEventListener('hashchange', this.updateDepartmentForHash);
    this.updateDepartmentForHash();

    if (this.iso == null) {
      this.iso = new Isotope(this.refs.isotopeContainer, {
        // options
        itemSelector: '.grid-item',
        layoutMode: 'masonry',
        initLayout: false,
      });
      setTimeout(() => {
        this.iso.shuffle();
        this.iso.arrange({
          filter: this.isotopeFilterFunction,
        });
      }, 1);
    }
  };

  shouldComponentUpdate = () => {
    return true;
  };

  componentWillUnmount = () => {
    window.removeEventListener('hashchange', this.updateDepartmentForHash);
    if (this.iso != null) {
      this.iso.destroy();
    }
  };

  isotopeFilterFunction = (itemElem) => {
    const groupedItems = this.groupedDepartments[this.state.active];
    if (groupedItems && groupedItems.length > 1) {
      let include = false;
      groupedItems.forEach(function (department) {
        if (itemElem.className.indexOf(department.node.id) !== -1) {
          include = true;
        }
      });

      return include || itemElem.className.indexOf(this.state.active) !== -1;
    }
    if (this.state.active === 'dep') {
      return itemElem.className.indexOf('dep-5a70c53194d32doc1022908350') === -1;
    }
    return itemElem.className.indexOf(this.state.active) !== -1;
  };

  filter = (departmentId, slug) => {
    this.setState(
      {
        active: departmentId,
      },
      function () {
        setTimeout(() => {
          if (this.iso != null) {
            this.iso.arrange({
              filter: this.isotopeFilterFunction,
            });
          }
        }, 100);
      },
    );
  };

  render = () => {
    let peopleOnPage = [];
    this.currentDepartments.map((node) => {
      const teamMembers = this.groupedPeople[node.id];
      if (teamMembers) {
        peopleOnPage = peopleOnPage.concat(teamMembers);
      }
    });

    // eliminate duplicates
    const usedKeys = {};
    peopleOnPage = peopleOnPage.filter((node) => {
      if (usedKeys[node.id]) {
        return false;
      }
      usedKeys[node.id] = true;
      return true;
    });

    return (
      <section className="defaultWidth">
        {typeof window !== 'undefined' && this.props.showNavigation && (
          <IsotopeTeamNavigation
            filterFn={this.filter}
            departments={this.props.departments.nodes}
            active={this.state.active}
          />
        )}
        <div className={styles.content} ref="isotopeContainer">
          {peopleOnPage.map((node) => {
            // return <div>{node.Name}</div>;
            return <TeamMember entry={node} key={node.id} />;
          })}
          <div className="clear" />
        </div>
      </section>
    );
  };
}
